// @ts-expect-error no types yet
import { TrackPageView } from '@zola/zola-ui/src/components/util/TrackPageView';

import Modal from 'react-modal';
import { BrowserRouter, withRouter } from 'react-router-dom';

import Notifications from '~/components/common/notifications/Notifications';
import ToastsContainer from '~/components/common/notifications/ToastsContainer';

import ModalRoot from './components/common/ModalRoot';

Modal.setAppElement('#root');

const TrackPageViewWithRouter = withRouter(TrackPageView);

interface ClientRouterProps {
  children: React.ReactNode;
}

/**
 * This component wraps client-side routes to provide tracking and containers for modals, notifications & toasts
 */
const ClientRouter = (props: ClientRouterProps) => {
  const { children } = props;
  return (
    <BrowserRouter>
      <TrackPageViewWithRouter />
      {children}
      <ModalRoot />
      <Notifications />
      <ToastsContainer />
    </BrowserRouter>
  );
};

export default ClientRouter;

/* eslint-disable prettier/prettier */
import { Route, Redirect, Switch } from 'react-router-dom';

import { loadable } from '~/util/routeUtils';

import ClientRouter from '../ClientRouter';

const BookedVendorsV2 = loadable(() => import('~/pages/couples/bookedVendors/BookedVendorsV2'));
const BudgetQuiz = loadable(() => import('~/pages/couples/explore/components/BudgetQuiz/BudgetQuizContainer'));
const Collections = loadable(() => import('~/pages/couples/collections/Collections'));
const CollectionsLandingPage = loadable(() => import('~/pages/couples/collections/CollectionsLandingPage'));
const Dashboard = loadable(() => import('~/pages/couples/inquiries/Dashboard'));
const InquiryView = loadable(() => import('~/pages/couples/inquiries/InquiryView'));
const MiniQuizPage = loadable(() => import('~/pages/couples/explore/MiniQuizPage'));
const MiniQuizQuickOnboardFlow = loadable(() => import('~/pages/couples/onboardV3/flowVersions/MiniQuizQuickOnboardFlow/MiniQuizQuickOnboardFlow'));
const NoMatch = loadable(() => import('~/pages/NoMatch/NoMatch'));
const OnboardV3 = loadable(() => import('~/pages/couples/onboardV3'));
const PublicReview = loadable(() => import('~/pages/reviews/PublicReview'));
const Review = loadable(() => import('~/pages/reviews/Review'));
const SavedSearch = loadable(() => import('~/pages/couples/explore/SavedSearch'));

const CouplesRoutes = () => (
  <ClientRouter>
    <Switch>
      <Route path="/inspire/onboard">
        <Redirect to="/inspiration/onboard" />
      </Route>
      <Route exact path="/inspiration/onboard" component={OnboardV3} />
      <Route exact path={['/wedding-vendors/budget-quiz/', '/wedding-vendors/budget-quiz/:currentStep']} component={BudgetQuiz} />
      <Route path="/wedding-vendors/onboard/mini-quiz" component={MiniQuizQuickOnboardFlow} />
      <Route path="/wedding-vendors/invites/quiz" component={MiniQuizPage} />
      <Route exact path="/inspire/matches">
        <Redirect to="/wedding-vendors/inquiries" />
      </Route>
      <Route exact path="/(inspire|wedding-vendors)/inquiries" component={Dashboard} />
      <Route exact path="/(inspire|wedding-vendors)/inquiries/connected">
        <Redirect to="/wedding-vendors/inquiries/active" />
      </Route>
      <Route exact path="/(inspire|wedding-vendors)/inquiries/:status(active|closed|invites)" component={Dashboard} />
      <Route exact path="/(inspire|wedding-vendors)/inquiries/booked" component={BookedVendorsV2} />
      <Route exact path="/wedding-vendors/inquiries/:uuid" component={InquiryView} />
      <Route exact path="/(inspire|inspiration)/favorites/inspiration">
        <Redirect to="/favorites/inspiration" />
      </Route>
      <Route exact={false} path="/(inspire|inspiration)/favorites">
        <Redirect to="/favorites/home" />
      </Route>
      <Route exact path="/favorites/home" component={CollectionsLandingPage} />
      <Route exact path="/favorites/:collectionKey(desserts|flowers|venue-ideas|vendors|inspiration)" component={Collections} />
      <Route exact path="/inspire/recommendations/:uuid" component={Review} />
      {/* This handles both public reviews & email verification requests */}
      <Route exact path="/inspire/public-recommendations/:uuid" component={PublicReview} />
      <Route exact path="/inspire">
        <Redirect to="/wedding-vendors" />
      </Route>
      <Route exact={false} path="/inspire/onboard-match">
        <Redirect to="/wedding-vendors" />
      </Route>
      <Route exact={false} path="/wedding-vendors/saved-search/:uuid" component={SavedSearch} />
      <Route component={NoMatch} />
    </Switch>
  </ClientRouter>
);

export default CouplesRoutes;

import ClientApp from '~/ClientApp';
import { UserContextProvider } from '~/contexts/UserContext';
import CouplesRoutes from '~/routes/CouplesRoutes';

const CouplesClient = () => (
  <UserContextProvider>
    <ClientApp>
      <CouplesRoutes />
    </ClientApp>
  </UserContextProvider>
);

export default CouplesClient;

import { Fragment, useState } from 'react';

import useEffectOnce from '@zola/zola-ui/src/hooks/useEffectOnce';

import { Provider } from 'react-redux';

import { getStore } from '~/store';

interface ClientAppProps {
  children: React.ReactNode;
  useStore?: boolean;
}

/**
 * This component is used in Next.js routes to make sure child components run in the browser
 *
 * @see https://colinhacks.com/essays/building-a-spa-with-nextjs
 */
const ClientApp = (props: ClientAppProps) => {
  const { children, useStore = true } = props;
  const [isClient, setIsClient] = useState(false);

  useEffectOnce(() => {
    setIsClient(true);
  });

  if (!isClient) {
    return null;
  }

  if (useStore) {
    return <Provider store={getStore()}>{children}</Provider>;
  }

  return <Fragment>{children}</Fragment>;
};

export default ClientApp;
